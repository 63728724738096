.hero-container {
    position: relative;
    overflow: hidden;
}

.hero-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/src/images/Vector_264.png');
    z-index: -1;
}

@keyframes moveBackground {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}
.custom-font {
    font-family: Poppins, sans-serif;
    
}